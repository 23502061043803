import React, { FunctionComponent } from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import MorePostsTiles from "../MorePostsTiles/MorePostsTiles"
import CustomH2 from "./CustomH2"
import CustomButton from "../CustomButton"
import { Link } from "gatsby"
import { mainRoutes } from "../../utils/routes"

export const BlogSection: FunctionComponent = () => {
  return (
    <Flex width="100%" flexDir="column" justifyContent="center" margin="0 auto">
      <Box>
        <CustomH2 text="Blog" />
        <Text textAlign="center" padding="0.5rem 0">
          As a creative outlet, I created a blog about anything interesting that
          comes up during my development endeavours.
        </Text>
      </Box>
      <Box>
        <MorePostsTiles totalPostsToShow={3} />
      </Box>
      <Box margin="0 auto 2rem auto">
        <Link to={mainRoutes[2].route}>
          <CustomButton text="View All Posts" />
        </Link>
      </Box>
    </Flex>
  )
}

export default BlogSection
