import React, { FunctionComponent } from "react"
import { Flex, Box, Divider } from "@chakra-ui/react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import LandingPageHeader from "../components/LandingPage/LandingPageHeader"
import BlogSection from "../components/LandingPage/BlogSection"
import ContactSection from "../components/LandingPage/ContactSection"

export const IndexPage: FunctionComponent = () => {
  const PageHeader = () => {
    return <LandingPageHeader />
  }

  return (
    <>
      <Layout preLayout={PageHeader()}>
        <Seo
          title="HendrikGruber.com"
          meta={[
            { name: "HendrikGruber.com", content: "Hendrik Gruber's Homepage" },
          ]}
        />
        <Flex flexDir="column">
          <Box>
            <BlogSection />
          </Box>
          <Divider />
          <Box marginTop="2rem">
            <ContactSection />
          </Box>
        </Flex>
      </Layout>
    </>
  )
}

export default IndexPage
