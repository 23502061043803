import { Flex, Heading, Text, Box } from "@chakra-ui/react"
import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import {
  colors,
  maxContentWidth,
  shadows,
  switchMobile,
} from "../../theme/theme"
import { Link } from "gatsby"

const LeftAlignedBox = styled(Box)`
  max-width: 22rem;
  width: 100%;
`

const StyledText = styled(Text)`
  padding: 0.5rem 0;
  max-width: 22rem;
  text-align: justify;
`

const LayoutWrapper = styled(Flex)`
  padding: 0.5rem 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  max-width: ${maxContentWidth};
  width: 100%;
  @media (max-width: ${switchMobile}) {
    padding: 0rem 0.5rem;
  }
`

export const CustomWrapper = styled(Flex)`
  align-items: center;
  margin: 1.5rem auto;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

const StyledPageHeader = styled(Flex)`
  width: 100%;
  height: 30rem;
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue2},
    ${colors.blue.blue5}
  );
  color: ${colors.white};
  padding: 0.5rem 3rem;
  box-shadow: ${shadows.buttonShadow};
  justify-content: center;
  @media (max-width: ${switchMobile}) {
    padding: 0.5rem 0.5rem;
    height: calc(100vh - 4.5rem);
    background-image: linear-gradient(
      to right,
      ${colors.blue.blue2},
      ${colors.blue.blue4}
    );
  }
`

export const LandingPageHeader: FunctionComponent = () => {
  return (
    <StyledPageHeader>
      <LayoutWrapper>
        <CustomWrapper>
          <LeftAlignedBox>
            <Text>Hey, I'm</Text>
          </LeftAlignedBox>
          <LeftAlignedBox>
            <Heading
              as="h1"
              style={{
                fontSize: "2.8rem",
                textShadow: shadows.containerShadow,
              }}
            >
              HENDRIK.
            </Heading>
          </LeftAlignedBox>
          <Box>
            <StyledText>
              I'm a developer with a passion for building websites in my free
              time. At the moment I'm pursuing my Master's Degree and also work
              as a freelancer. If you're looking for someone to create a website
              for your small business, club, or hobby, then check out{" "}
              <Link
                to="/hire"
                style={{
                  color: colors.blue.blue8,
                  textDecoration: "underline",
                }}
              >
                {" "}
                my hire me page
              </Link>
              .
            </StyledText>
          </Box>
        </CustomWrapper>
      </LayoutWrapper>
    </StyledPageHeader>
  )
}

export default LandingPageHeader
